import client from './http';

class LanguagesService {

    constructor(axios) {
        this.axios = axios;
    }

    getList(search) {
        return this.axios.get('/locale', {params: {search: JSON.stringify(search)}});
    }

}

const languagesService = new LanguagesService(client);
export default languagesService;
